import React from 'react'
import Swiper from 'react-id-swiper';
import slide1 from "../../images/gallery/vito/slide1.jpg"
import slide2 from "../../images/gallery/vito/slide2.jpg"
import slide3 from "../../images/gallery/vito/slide3.jpg"
import slide4 from "../../images/gallery/vito/slide4.jpg"
import slide5 from "../../images/gallery/vito/slide5.jpg"
class PartnersSlider extends React.Component {
    render() {
        const params = {
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
              },
              loop: true,  
          }
   
      return(
                    <Swiper {...params}>
                        <div className="slide"><img src={slide1} alt="Mercedes-Benz Vito - Vip Cars Sarajevo/Rent a Car" /></div>
                        <div className="slide"><img src={slide2} alt="Mercedes-Benz Vito - Vip Cars Sarajevo/Rent a Car" /></div>
                        <div className="slide"><img src={slide3} alt="Mercedes-Benz Vito - Vip Cars Sarajevo/Rent a Car" /></div>
                        <div className="slide"><img src={slide4} alt="Mercedes-Benz Vito - Vip Cars Sarajevo/Rent a Car" /></div>
                        <div className="slide"><img src={slide5} alt="Mercedes-Benz Vito - Vip Cars Sarajevo/Rent a Car" /></div>
                    </Swiper>         
      )
    }
  }
   
  export default PartnersSlider
