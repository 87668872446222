import React from 'react'
import Gallery from '../components/Gallery/gallery_vito'
import Link from 'gatsby-link'
import {prefix, link_vehicles} from '../components/langPrefix'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Layout from '../components/layout'
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";

const GalleryPageVito = () => (
    <Layout>
      
    <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'  className="section gallery">
    <Link to={prefix + link_vehicles} className="close-btn"><FontAwesomeIcon icon="times"/></Link>
         <div className="container">
        <Gallery/>
        </div>
   </ScrollAnimation>
 
   </Layout>
)

export default GalleryPageVito
